import WalletWithCashImage from "@assets/images/wallet-with-cash.png";
import { SpinerPage } from "@components/loaders/spiner";
import { FinanceService } from "@services/finance.service";
import { useThemeStore } from "@stores/theme.store";
import { useQuery } from "@tanstack/react-query";
import { toRp } from "@utils/helper";
import { linkPage } from "@utils/router";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const PocketPage = () => {
  const navigate = useNavigate();
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);

  const { data, isPending } = useQuery({
    queryKey: [FinanceService.queries.GET_FINANCE_POCKET],
    queryFn: () => FinanceService.pocket(),
  });

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Daftar Kantong",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ leading: "back", title: "Daftar Kantong" });
  }, [setFooter, setHeader, setAppBar]);

  return (
    <>
      <SpinerPage show={isPending} />
      <div className="tw-mx-4 tw-grid tw-grid-cols-2">
        {data?.data?.map((item, idx) => (
          <div
            key={idx}
            className="card card-style"
            onClick={() =>
              navigate(
                linkPage.FINANCE_POCKET_HISTORY.replace(
                  ":pocket",
                  item.typePocket
                ),
                { state: item }
              )
            }
          >
            <div className="content p-1 color-theme">
              <img
                src={WalletWithCashImage}
                alt="wallet-with-cash"
                className="tw-w-1/3"
              />
              <div className="tw-mt-4 tw-font-medium tw-text-base">
                {item.namePocket}
              </div>
              <div className="m-0">{toRp(item.balance)}</div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
