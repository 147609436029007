import { PiSneaker } from "react-icons/pi";
import { ObjectSchema, object, string } from "yup";

// ------------- FORM BIODATA -------------
export type FormBiodata = {
  nama: string;
  kotaLahir: string;
  tanggalLahir: string;
  namaAyah: string;
  namaIbu: string;
  address: string;
};

export const formBiodataSchema: ObjectSchema<FormBiodata> = object({
  nama: string().required(),
  kotaLahir: string().required(),
  tanggalLahir: string().required(),
  namaAyah: string().required(),
  namaIbu: string().required(),
  address: string().required(),
});

export type FormBiography = {
  nis: string;
  namasiswa: string;
  kotaLahir: string;
  tanggalLahir: string;
  namaAyah: string;
  namaIbu: string;
  alamat: string;
  pekerjaanAyah: string;
  pekerjaanIbu: string;
  pendidikanAyah: string;
  pendidikanIbu: string;
  telephon: string;
};

export const formBiographySchema: ObjectSchema<FormBiography> = object({
  nis: string().required(),
  namasiswa: string().required(),
  kotaLahir: string().required(),
  tanggalLahir: string().required(),
  namaAyah: string().required(),
  namaIbu: string().required(),
  alamat: string().required(),
  pekerjaanAyah: string().required(),
  pekerjaanIbu: string().required(),
  pendidikanAyah: string().required(),
  pendidikanIbu: string().required(),
  telephon: string().required(),
});
// ------------- FORM BIODATA -------------
