import { Spiner, SpinerPage } from "@components/loaders/spiner";
import { MadingApps } from "@services/dtos/home.dto";
import { HomeService } from "@services/home.service";
import { SchoolService } from "@services/school.service";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { useSchoolStore } from "@stores/school.store";
import { useThemeStore } from "@stores/theme.store";
import { useQuery } from "@tanstack/react-query";
import {
  androidChangeThemeStatusBar,
  androidIsAvailableBiomatric,
  androidUnreadInbox,
} from "@utils/android";
import { toRp } from "@utils/helper";
import { linkPage } from "@utils/router";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  FcBusinessContact,
  FcGraduationCap,
  FcMoneyTransfer,
  FcOvertime,
  FcPlanner,
  FcShop,
  FcSurvey,
  FcWorkflow,
} from "react-icons/fc";
import { HiCurrencyDollar, HiNewspaper } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";

export const DashboardPage = () => {
  const navigate = useNavigate();
  const { updateInfo } = useSchoolStore((state) => state);
  const [loadingOpenSlider, setLoadingOpenSlider] = useState<boolean>(false);
  const { themeMode, setAppBar, setFooter, setHeader } = useThemeStore(
    (state) => state
  );

  const { data: infoSchool } = useQuery({
    queryKey: [SchoolService.queries.GET_SCHOOL_INFO],
    queryFn: SchoolService.info,
  });

  const { data: dataUangSaku, isPending: isPendingUangSaku } = useQuery({
    queryKey: [HomeService.queries.GET_BILLS_AND_BALANCES],
    queryFn: HomeService.billsAndBalances,
  });

  const { data: dataSliders, isPending: isPendingSliders } = useQuery({
    queryKey: [HomeService.queries.GET_SLIDER],
    queryFn: HomeService.sliders,
  });
  const kategori = "*";
  const { data: dataMading, isPending: isPendingMading } = useQuery({
    queryKey: [HomeService.queries.GET_MADING, kategori],
    queryFn: () => HomeService.mading(kategori),
  });

  // const { data: dataNis } = useQuery({
  //   queryKey: [SchoolService.queries.GET_SCHOOL_STUDENT_BIO],
  //   queryFn: SchoolService.studentBio,
  // });

  useEffect(() => {
    if (infoSchool) {
      updateInfo({
        name: infoSchool?.data?.namasekolah,
        moto: infoSchool?.data?.moto,
      });
    }
  }, [infoSchool, updateInfo]);

  // useEffect(() => {
  //   localStorage.setItem("nis", dataNis?.data?.nis ?? "");
  // }, [dataNis]);

  useEffect(() => {
    if (themeMode === "light") {
      androidChangeThemeStatusBar("#8CC152", "dark");
    }

    if (themeMode === "dark") {
      androidChangeThemeStatusBar("#47602B", "light");
    }

    const setupUnreadInbox = setTimeout(() => androidUnreadInbox(), 1000);
    const setupBiometric = setTimeout(() => {
      localStorage.setItem("is-biometric", "false");
      androidIsAvailableBiomatric();
    }, 1000);

    return () => {
      clearTimeout(setupBiometric);
      clearTimeout(setupUnreadInbox);
    };
  }, [themeMode]);

  useEffect(() => {
    setFooter(true);
    setHeader({
      type: "large",
      buttonBack: false,
      avatar: true,
      title: (
        <>
          Pondok Pesantren <br /> Al-Ma'tuq
        </>
      ),
    });
    setAppBar({ title: "Home" });
  }, [setAppBar, setFooter, setHeader]);

  const onClickSlider = (
    type: "IN_ROUTE" | "IN_APP" | "OUT_APP",
    link: string
  ) => {
    switch (type) {
      case "IN_ROUTE":
        navigate(link);
        break;
      case "IN_APP":
        document.location.href = link;
        break;
      case "OUT_APP":
        setLoadingOpenSlider(true);
        setTimeout(() => {
          setLoadingOpenSlider(false);
        }, 3000);
        document.location.href = link;
        break;
    }
  };

  const onOpenDetail = (news: MadingApps) => {
    navigate(linkPage.SCHOOL_NEWS_DETAIL, { state: news });
  };

  return (
    <>
      <SpinerPage show={loadingOpenSlider} />
      <Splide
        options={{
          type: "loop",
          autoplay: true,
          interval: 4000,
          perPage: 1,
          padding: "2rem",
          arrows: false,
        }}
        className="single-slider"
      >
        {isPendingSliders
          ? [1, 2, 3].map((key) => (
              <SplideSlide key={key}>
                <div
                  className="card rounded-l mx-2 text-center shadow-l bg-theme d-flex"
                  style={{ height: 289 }}
                >
                  <Spiner className="!tw-text-gray-500 dark:!tw-text-gray-500 tw-w-8 tw-h-8 m-auto" />
                </div>
              </SplideSlide>
            ))
          : (dataSliders?.data ?? []).slice(0, 3).map((slider, idx) => (
              <SplideSlide
                key={idx}
                onClick={() => onClickSlider(slider.type, slider.linkAction)}
              >
                <div
                  className="card rounded-l mx-2 text-center shadow-l"
                  style={{
                    height: 289,
                    backgroundImage: `url(${slider.imageUrl})`,
                  }}
                >
                  <div className="card-bottom">
                    <h1 className="font-24 font-700">{slider.title}</h1>
                    <p className="boxed-text-xl">{slider.description}</p>
                  </div>
                  <div className="card-overlay bg-gradient-fade"></div>
                </div>
              </SplideSlide>
            ))}
      </Splide>

      <div className="card preload-img bg-header-footer-default mt-4">
        <div className="card-body">
          <div className="card card-style ms-0 me-0 mb-0 bg-theme">
            <div className="d-flex py-2">
              <div
                className="flex-fill d-flex border-end ps-2"
                onClick={() => navigate(linkPage.FINANCE_POCKET)}
              >
                {isPendingUangSaku ? (
                  <div className="tw-py-2 m-auto">
                    <Spiner className="!tw-text-gray-500 dark:!tw-text-gray-500 tw-w-6 tw-h-6" />
                  </div>
                ) : (
                  <>
                    <HiCurrencyDollar
                      className="float-start me-2 color-yellow-dark my-auto"
                      size={40}
                    />
                    <div className="d-flex flex-column w-100 my-auto">
                      <span className="lh-1 fw-light" style={{ fontSize: 12 }}>
                        Uang Saku
                      </span>
                      <span
                        className="lh-sm fw-semibold"
                        style={{ fontSize: 15 }}
                      >
                        {toRp(dataUangSaku?.data?.saldo ?? 0)}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div
                className="flex-fill d-flex ps-2"
                onClick={() => navigate(linkPage.FINANCE_BILL)}
              >
                {isPendingUangSaku ? (
                  <div className="tw-py-2 m-auto">
                    <Spiner className="!tw-text-gray-500 dark:!tw-text-gray-500 tw-w-6 tw-h-6" />
                  </div>
                ) : (
                  <>
                    <HiNewspaper
                      className="float-start me-2 color-blue-dark my-auto"
                      size={40}
                    />
                    <div className="d-flex flex-column w-100 my-auto">
                      <span className="lh-1 fw-light" style={{ fontSize: 12 }}>
                        Tagihan
                      </span>
                      <span
                        className="lh-sm fw-semibold"
                        style={{ fontSize: 15 }}
                      >
                        {toRp(dataUangSaku?.data?.tagihan ?? 0)}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card-overlay bg-highlight opacity-90"></div>
        <div className="card-overlay dark-mode-tint"></div>
      </div>

      <div className="content mb-3">
        <h5 className="float-start font-16 font-500">Rekomendasi Menu</h5>
        <div className="clearfix"></div>
      </div>

      <div className="tw-mx-4 tw-grid tw-grid-cols-4 tw-gap-x-3 tw-gap-y-5 tw-mb-8">
        <div
          onClick={() => navigate(linkPage.MARKETPLACE_PRODUCT)}
          className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2"
        >
          <FcShop className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Al-Barokah</span>
        </div>
        <div
          onClick={() =>
            navigate(linkPage.FINANCE_VIRTUAL_ACCOUNT_REQUEST, {
              state: { title: "Request Transaksi", tagihan: true },
            })
          }
          className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2"
        >
          <FcMoneyTransfer className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Request Transaksi</span>
        </div>
        <div
          onClick={() => navigate(linkPage.ACTIVITY_FORM)}
          className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer"
        >
          <FcPlanner className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Form Kegiatan</span>
        </div>
        <div
          onClick={() => navigate(linkPage.PERMIT_FORM)}
          className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2"
        >
          <FcOvertime className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Request Izin</span>
        </div>
        <div
          onClick={() => navigate(linkPage.SCHOOL_STUDENT_BIO)}
          className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2"
        >
          <FcBusinessContact className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Biodata</span>
        </div>
        <div
          onClick={() => navigate(linkPage.SCHOOL_STUDENT_ABSENCES)}
          className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2"
        >
          <FcSurvey className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Absensi</span>
        </div>
        <div
          onClick={() => navigate(linkPage.SCHOOL_STUDENT_ACHIEVEMENT)}
          className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2"
        >
          <FcGraduationCap className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Prestasi</span>
        </div>
        <div
          onClick={() => navigate(linkPage.HOME_OTHER_MENUS)}
          className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2"
        >
          <FcWorkflow className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Lainnya</span>
        </div>
      </div>

      {(dataMading?.data ?? []).length === 0 ? (
        <></>
      ) : (
        <>
          <div className="content mb-3 mt-0">
            <h5 className="float-start font-16 font-500">Berita Terkini</h5>
            <Link
              to={linkPage.SCHOOL_NEWS}
              className="float-end font-12 color-highlight mt-n1"
            >
              Lihat Semua
            </Link>
            <div className="clearfix"></div>
          </div>
          <Splide
            options={{
              perPage: 1,
              arrows: false,
            }}
            className="slider-no-dots"
          >
            {isPendingMading
              ? [1, 2, 3].map((key) => (
                  <SplideSlide key={key}>
                    <div
                      className="card rounded-l mx-2 text-center shadow-l bg-theme d-flex"
                      style={{ height: 289 }}
                    >
                      <Spiner className="!tw-text-gray-500 dark:!tw-text-gray-500 tw-w-8 tw-h-8 m-auto" />
                    </div>
                  </SplideSlide>
                ))
              : (dataMading?.data ?? [])
                  .sort((a, b) => moment(b.tanggal).diff(moment(a.tanggal)))
                  .slice(0, 3)
                  .map((mading, idx) => (
                    <SplideSlide
                      className="ps-3 pe-2 !tw-w-10/12 mb-4"
                      key={idx}
                      onClick={() => onOpenDetail(mading)}
                    >
                      <div className="bg-theme pb-3 rounded-m shadow-l overflow-hidden">
                        <div
                          className="card mb-1 tw-h-[150px]"
                          style={{ backgroundImage: `url(${mading.gambar})` }}
                        ></div>
                        <h4 className="mb-0 ps-2 pe-2 pt-2 font-14 tw-font-medium">
                          {mading.judul}
                        </h4>
                        <small className="ps-2 pe-2">
                          {moment(mading.tanggal ?? Date.now()).format(
                            "dddd, DD MMMM YYYY | HH:mm"
                          )}
                        </small>
                      </div>
                    </SplideSlide>
                  ))}
          </Splide>
        </>
      )}
    </>
  );
};
