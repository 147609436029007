import { Spiner } from "@components/loaders/spiner";
import { MadingApps } from "@services/dtos/home.dto";
import { HomeService } from "@services/home.service";
import { useThemeStore } from "@stores/theme.store";
import { useQuery } from "@tanstack/react-query";
import { linkPage } from "@utils/router";
import moment from "moment";
import { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { FcFinePrint } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";

export const NewsPage = () => {
  const navigate = useNavigate();
  const [kategori, setkategori] = useState<string>("*");
  const [limit, setLimit] = useState<string>("10");
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);
  const location = useLocation();

  const { data: dataMading, isPending: isPendingMading } = useQuery({
    queryKey: [HomeService.queries.GET_MADING, kategori],
    queryFn: () => HomeService.mading(kategori),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    setFooter(false);
    setHeader({
      type: "small",
      title: "Berita Terkini",
      buttonBack: true,
      avatar: false,
    });
    setAppBar({ leading: "back", title: "Berita Terkini" });
  }, [setFooter, setHeader, setAppBar, location.pathname]);

  const onOpenDetail = (news: MadingApps) => {
    navigate(linkPage.SCHOOL_NEWS_DETAIL, { state: news });
  };

  return (
    <>
      <div className="card card-style">
        <div className="content mb-0">
          <p className="mb-3">Data yang ditampilkan berdasarkan Kategori</p>

          <div className="input-style has-borders input-style-always-active no-icon mb-4">
            <label htmlFor="form5" className="color-highlight font-500">
              Filter Kategori ditampilkan
            </label>
            <select
              defaultValue="10"
              onChange={(e) => setkategori(e.target.value)}
              className="bg-theme"
            >
              <option value="default" disabled>
                Kategori yang ditampilkan
              </option>
              <option value="*">Semua</option>
              <option value="FORMAL">FORMAL</option>
              <option value="NONFORMAL">NONFORMAL</option>
            </select>
            <span>
              <i>
                <FaChevronDown />
              </i>
            </span>
          </div>
        </div>
      </div>
      {isPendingMading ? (
        <div
          className="card rounded-l mx-2 text-center shadow-l bg-theme d-flex"
          style={{ height: 289 }}
        >
          <Spiner className="!tw-text-gray-500 dark:!tw-text-gray-500 tw-w-8 tw-h-8 m-auto" />
        </div>
      ) : dataMading &&
        dataMading.data !== null &&
        dataMading.data.length > 0 ? (
        (dataMading?.data ?? [])
          .sort((a, b) => moment(b.tanggal).diff(moment(a.tanggal)))
          .map((mading, idx) => (
            <div
              className="ps-3 pe-3 mb-4"
              key={idx}
              onClick={() => onOpenDetail(mading)}
            >
              <div className="bg-theme pb-3 rounded-m shadow-l overflow-hidden">
                <div
                  className="card mb-1 tw-h-[150px]"
                  style={{ backgroundImage: `url(${mading.gambar})` }}
                ></div>
                <h4 className="mb-0 ps-2 pe-2 pt-2 font-14 tw-font-medium">
                  {mading.judul}
                </h4>
                <small className="ps-2 pe-2">
                  {moment(mading.tanggal ?? Date.now()).format(
                    "dddd, DD MMMM YYYY | HH:mm"
                  )}
                </small>
              </div>
            </div>
          ))
      ) : (
        <div
          className="card rounded-l mx-3 text-center shadow-l bg-theme d-flex"
          style={{ height: 289 }}
        >
          <div className="tw-p-8 text-center" style={{ height: "100%" }}>
            <div className="d-grid" style={{ height: "100%" }}>
              <FcFinePrint size={50} className="my-auto mx-auto" />
              <div>Data tidak ditemukan</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
