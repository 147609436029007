import {
  FormBiodata,
  FormBiography,
} from "@components/resolvers/school.resolvers";
import { BaseService } from "./base.service";
import {
  infoResponse,
  StudentAbsencesResponse,
  StudentAchievementResponse,
  StudentBiographyResponse,
  StudentBioResponse,
  StudentHealthRecordResponse,
  StudentHealthResponse,
  StudentViolationsResponse,
} from "./dtos/school.dto";
import axios from "axios";

interface SchoolServiceInterface {
  queries: {
    GET_SCHOOL_INFO: string;
    GET_SCHOOL_STUDENT_BIO: string;
    GET_SCHOOL_STUDENT_BIOGRAPHY: string;
    GET_SCHOOL_STUDENT_HEALTH: string;
    GET_SCHOOL_STUDENT_HEALTH_RECORD: string;
    GET_SCHOOL_STUDENT_VIOLATIONS: string;
    GET_SCHOOL_STUDENT_ACHIEVEMENT: string;
    GET_SCHOOL_STUDENT_ABSENCES: string;
  };
  info(): Promise<infoResponse>;
  studentBio(): Promise<StudentBioResponse>;
  studentBiography(nis: string): Promise<StudentBiographyResponse>;
  updateStudentBio(payload: FormBiodata): Promise<StudentBioResponse>;
  updateStudentBiography(
    payload: FormBiography
  ): Promise<StudentBiographyResponse>;
  studentHealth(limit: string): Promise<StudentHealthResponse>;
  studentHealthRecord(
    nis: string,
    kategori: string,
    limit: string
  ): Promise<StudentHealthRecordResponse>;
  studentViolations(limit: string): Promise<StudentViolationsResponse>;
  studentAchievement(limit: string): Promise<StudentAchievementResponse>;
  studentAbsences(limit: string): Promise<StudentAbsencesResponse>;
}
export const SchoolService: SchoolServiceInterface = {
  queries: {
    GET_SCHOOL_INFO: "GET_SCHOOL_INFO",
    GET_SCHOOL_STUDENT_BIO: "GET_SCHOOL_STUDENT_BIO",
    GET_SCHOOL_STUDENT_BIOGRAPHY: "GET_SCHOOL_STUDENT_BIOGRAPHY",
    GET_SCHOOL_STUDENT_HEALTH: "GET_SCHOOL_STUDENT_HEALTH",
    GET_SCHOOL_STUDENT_HEALTH_RECORD: "GET_SCHOOL_STUDENT_HEALTH_RECORD",
    GET_SCHOOL_STUDENT_VIOLATIONS: "GET_SCHOOL_STUDENT_VIOLATIONS",
    GET_SCHOOL_STUDENT_ACHIEVEMENT: "GET_SCHOOL_STUDENT_ACHIEVEMENT",
    GET_SCHOOL_STUDENT_ABSENCES: "GET_SCHOOL_STUDENT_ABSENCES",
  },
  info: async () => {
    try {
      const request = await axios.get(
        "https://almatuq.aplikasi-pesantren.id/api/pondok/info-sekolah",
        {
          headers: {
            token:
              "$2y$12$.ztMaYznNLvwXEBgQ6G.TunKw/ONAj9uTnCmBdsnnDXOkzikQxxBG",
          },
        }
      );
      return {
        success: request.data.status,
        message: request.data.message,
        data: request.data.data,
      };
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  studentBio: async () => {
    try {
      const request = await BaseService.request().get<StudentBioResponse>(
        "/school/student-bio"
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },

  studentBiography: async (nis: string) => {
    try {
      const request = await axios.get(
        `https://almatuq.aplikasi-pesantren.id/api/siswa/data-siswa/${nis}`,
        {
          headers: {
            token:
              "$2y$12$.ztMaYznNLvwXEBgQ6G.TunKw/ONAj9uTnCmBdsnnDXOkzikQxxBG",
          },
        }
      );
      return {
        success: request.data.status,
        message: request.data.message,
        data: request.data.data,
      };
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  updateStudentBio: async (payload: FormBiodata) => {
    try {
      const request = await BaseService.request().post<StudentBioResponse>(
        "/school/update-student-bio",
        payload
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },

  updateStudentBiography: async (payload: FormBiography) => {
    try {
      const request = await axios.post(
        "https://almatuq.aplikasi-pesantren.id/api/siswa/update-data-siswa",
        payload,
        {
          headers: {
            token:
              "$2y$12$.ztMaYznNLvwXEBgQ6G.TunKw/ONAj9uTnCmBdsnnDXOkzikQxxBG",
          },
        }
      );
      return {
        success: request.data.status,
        message: request.data.message,
        data: request.data,
      };
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  studentHealth: async (limit: string) => {
    try {
      const request = await BaseService.request().get<StudentHealthResponse>(
        "/school/student-health",
        { params: { limit } }
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  studentHealthRecord: async (nis: string, kategori: string, limit: string) => {
    try {
      const request = await axios.get<StudentHealthRecordResponse>(
        `https://almatuq.aplikasi-pesantren.id/api/kesehatan/record/${nis}/${kategori}/${limit}`,
        {
          headers: {
            token:
              "$2y$12$.ztMaYznNLvwXEBgQ6G.TunKw/ONAj9uTnCmBdsnnDXOkzikQxxBG",
          },
        }
      );

      return {
        success: request.data.success,
        message: request.data.message,
        data: request.data.data,
      };
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  studentViolations: async (limit: string) => {
    try {
      const request =
        await BaseService.request().get<StudentViolationsResponse>(
          "/school/student-violations",
          { params: { limit } }
        );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  studentAchievement: async (limit: string) => {
    try {
      const request =
        await BaseService.request().get<StudentAchievementResponse>(
          "/school/student-achievement",
          { params: { limit } }
        );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  studentAbsences: async (limit: string) => {
    try {
      const request = await BaseService.request().get<StudentAbsencesResponse>(
        "/school/student-absences",
        { params: { limit } }
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
};
