import { PopupConfirm } from "@components/generals/popup";
import { useThemeStore } from "@stores/theme.store";
import { androidRemoveInbox, androidUnreadInbox } from "@utils/android";
import moment from "moment";
import { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";

export const InboxDetailPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [popup, setPopup] = useState<boolean>(false);
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Detail Kotak Masuk",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ title: "Detail Kotak Masuk", leading: "back" });
  }, [setFooter, setHeader, setAppBar]);

  const onRemoveInbox = () => {
    androidRemoveInbox(`${state.id}`);
    androidUnreadInbox();
    navigate(-1);
  };

  return (
    <>
      <div className="card card-style">
        <div className="content d-flex flex-column mb-0">
          <span className="tw-font-medium tw-text-base">
            {state.title ?? "-"}
          </span>
          <small>
            {moment(state.createdAt).format("dddd, DD MMMM YYYY | HH:mm")}
          </small>
          <p className="my-2">{state.message ?? "-"}</p>
          <div>
            <button
              onClick={() => setPopup(true)}
              className="btn btn-border btn-xs mb-3 rounded-sm font-900 border-red-dark color-red-dark bg-theme"
            >
              <FaTrash size={12} /> Hapus Inbox
            </button>
          </div>
        </div>
      </div>
      <PopupConfirm
        height={170}
        show={popup}
        title="Hapus inbox ini?"
        message="Data inbox akan dihapus secara permanen dan tidak bisa dikembalikan lagi!"
        textConfirm="Ya, Hapus"
        textCancel="Batalkan"
        onConfirm={onRemoveInbox}
        onCancel={() => setPopup(false)}
      />
    </>
  );
};
