import { PopupConfirm } from "@components/generals/popup";
import { SpinerPage } from "@components/loaders/spiner";
import { FinanceService } from "@services/finance.service";
import { useNotificationStore } from "@stores/notification.store";
import { useThemeStore } from "@stores/theme.store";
import { useMutation } from "@tanstack/react-query";
import { toRp } from "@utils/helper";
import copy from "copy-to-clipboard";
import moment from "moment";
import { useEffect, useState } from "react";
// import { set } from "react-hook-form";
import { IoCopy } from "react-icons/io5";
import { useLocation } from "react-router-dom";

export const VirtualAccountReportDetailPage = () => {
  const { state } = useLocation();
  const location = useLocation();
  const { pushNotification } = useNotificationStore((state) => state);
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);
  const [status, setStatus] = useState<string>(state?.vaStatus);
  const [trxID, setTrxID] = useState<string>("");
  const [popupCancel, setPopupCancel] = useState<boolean>(false);
  const [popupTitle, setPopupTitle] = useState<string>("");
  const [popupMessage, setPopupMessage] = useState<string>("");
  const [popupTextConfirm, setPopupTextConfirm] = useState<string>("");

  const { mutate, isPending } = useMutation({
    mutationFn: FinanceService.virtualAccountCancel,
    onSuccess: (response) => {
      if (response.success) {
        setStatus("EXPIRED");
        setTrxID("");
        pushNotification({
          type: "success",
          message: "Virtual account berhasil dibatalkann",
        });
      } else {
        pushNotification({
          type: "error",
          message: response.message,
        });
      }
    },
    onError: (error: any) => {
      pushNotification({
        type: "error",
        message: error.message ?? "Internal Error",
      });
    },
  });

  const { mutate: mutateConfirm, isPending: isPendingConfirm } = useMutation({
    mutationFn: FinanceService.virtualAccountConfirm,
    onSuccess: (response) => {
      if (response.success) {
        setStatus("ONHOLD");
        pushNotification({
          type: "success",
          message: "Menunggu konfirmasi dari Admin Keuangan",
        });
      } else {
        pushNotification({
          type: "error",
          message: response.message,
        });
      }
    },
    onError: (error: any) => {
      pushNotification({
        type: "error",
        message: error.message ?? "Internal Error",
      });
    },
  });

  const { mutate: mutateCheckStatus, isPending: isPendingCheckStatus } =
    useMutation({
      mutationFn: FinanceService.virtualAccountCheckStatus,
      onSuccess: (response) => {
        if (response.success) {
          setStatus("COMPLETED");
          // pushNotification({
          //   type: "success",
          //   message: "Menunggu konfirmasi dari Admin Keuangan",
          // });
        } else {
          pushNotification({
            type: "warning",
            message: response.message,
          });
        }
      },
      onError: (error: any) => {
        pushNotification({
          type: "error",
          message: error.message ?? "Internal Error",
        });
      },
    });

  useEffect(() => {
    window.scrollTo(0, 0);
    setFooter(false);
    setHeader({
      type: "small",
      title: "Detail Laporan",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ title: "Detail Laporan", leading: "back" });
  }, [setFooter, setHeader, setAppBar, location.pathname]);

  const onCopy = () => {
    copy(state?.vaNumber);
    pushNotification({
      type: "success",
      message: "Nomor VA berhasil disalin!",
    });
  };

  const onCopyNominal = () => {
    copy(state?.vaTrxAmount);
    pushNotification({
      type: "success",
      message: "Nominal Pembayaran berhasil disalin!",
    });
  };

  const onOpenPopupConfirm = (id: string) => {
    setTrxID(id);
    setPopupTitle("Konfirmasi");
    setPopupMessage("Saya sudah melakukan transfer pembayaran");
    setPopupTextConfirm("Konfirmasi");
    setPopupCancel(true);
  };

  const onOpenPopupCancel = (id: string) => {
    setTrxID(id);
    setPopupTitle("Batalkan");
    setPopupMessage(
      "Jika dibatalkan maka status transaksi menjadi kadaluarsa dan tidak bisa digunakan kembali"
    );
    setPopupTextConfirm("Ya, Batalkan");
    setPopupCancel(true);
  };

  const handleConfirm = (title: string) => {
    if (title === "Batalkan") {
      setPopupCancel(false);
      mutate(trxID);
    } else {
      setPopupCancel(false);
      mutateConfirm(trxID);
    }
  };

  const handleCheckStatus = () => {
    mutateCheckStatus(state?.vaTrxId ?? "");
  };

  // const onCancel = () => {
  //   setPopupCancel(false);
  //   mutate(trxID);
  // };

  return (
    <>
      <SpinerPage
        show={isPending || isPendingConfirm || isPendingCheckStatus}
      />
      <div className="card card-style">
        <div className="content d-flex flex-column mb-0">
          <div className="color-highlight">ID Transaksi</div>
          <div className="color-theme mb-3">{state?.vaTrxId ?? "-"}</div>
          <div className="color-highlight">Nomor Rekening</div>
          <div
            className="color-magenta-dark mb-3 tw-text-lg tw-font-semibold tw-cursor-pointer"
            onClick={onCopy}
          >
            {state?.vaNumber ?? "-"}&nbsp;
            <i>
              <IoCopy />
            </i>
          </div>
          <div className="color-highlight">Atas Nama</div>
          <div className="color-theme mb-3">{state?.vaName ?? "-"}</div>
          <div className="color-highlight">Nama Bank</div>
          <div className="color-theme mb-3">{state?.vaBankName ?? "-"}</div>
          <div className="color-highlight">Status Transaksi</div>
          <div className="color-theme mb-3">
            {status === "PENDING" ? (
              <span className="badge bg-orange-dark color-white font-10 tw-py-1 px-2 rounded-2">
                PENDING
              </span>
            ) : status === "COMPLETED" ? (
              <span className="badge bg-green-dark color-white font-10 tw-py-1 px-2 rounded-2">
                SELESAI
              </span>
            ) : status === "ONHOLD" ? (
              <span className="badge bg-blue-dark color-white font-10 tw-py-1 px-2 rounded-2">
                ONHOLD
              </span>
            ) : (
              <span className="badge bg-red-dark color-white font-10 tw-py-1 px-2 rounded-2">
                KADALUARSA
              </span>
            )}
          </div>
          {state?.type !== "MANUAL" && (
            <>
              <div className="color-highlight">Nominal Transaksi</div>
              <div className="color-theme mb-3">
                {toRp((state?.vaTrxAmount ?? 0) - (state?.vaBankFee ?? 0))}
              </div>
              <div className="color-highlight">Biaya Admin Bank</div>
              <div className="color-theme mb-3">
                {toRp(state?.vaBankFee ?? 0)}
              </div>
            </>
          )}
          <div className="color-highlight">Total</div>
          <div
            className="color-magenta-dark mb-3 tw-text-lg tw-font-semibold tw-cursor-pointer"
            onClick={onCopyNominal}
          >
            {toRp(state?.vaTrxAmount ?? 0)}&nbsp;
            <i>
              <IoCopy />
            </i>
          </div>
          <div className="color-highlight">Waktu Kadaluarsa</div>
          <div className="color-theme mb-3">
            {state?.vaDateExpired != null
              ? moment(state!.vaDateExpired).format(
                  "dddd, DD MMMM YYYY | HH:mm"
                )
              : "-"}
          </div>
          <div className="color-highlight">Waktu Bayar</div>
          <div className="color-theme mb-3">
            {state?.vaDatePayment != null
              ? moment(state!.vaDatePayment).format(
                  "dddd, DD MMMM YYYY | HH:mm"
                )
              : "-"}
          </div>
        </div>
      </div>

      {status === "PENDING" && (
        <div className="card card-style !tw-mb-4">
          <div className="content">
            {state?.type !== "MANUAL" && (
              <>
                <button
                  className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-red-dark tw-grow tw-w-full tw-h-11"
                  onClick={() => onOpenPopupCancel(state?.vaTrxId ?? "")}
                >
                  Batalkan Pembayaran
                </button>
              </>
            )}
            {state?.type === "MANUAL" && (
              <>
                <button
                  className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-green-dark tw-grow tw-w-full tw-h-11 mb-3"
                  onClick={() => onOpenPopupConfirm(state?.vaTrxId ?? "")}
                >
                  Saya Sudah Transfer Pembayaran
                </button>

                <button
                  className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-red-dark tw-grow tw-w-full tw-h-11"
                  onClick={() => onOpenPopupCancel(state?.vaTrxId ?? "")}
                >
                  Batalkan Pembayaran
                </button>
              </>
            )}
          </div>
        </div>
      )}

      {status === "ONHOLD" && (
        <>
          {state?.type === "MANUAL" && (
            <div className="card card-style !tw-mb-4">
              <div className="content">
                <>
                  <button
                    className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-green-dark tw-grow tw-w-full tw-h-11"
                    onClick={() => handleCheckStatus()}
                  >
                    Cek Status Transaksi
                  </button>
                </>
              </div>
            </div>
          )}
        </>
      )}

      <PopupConfirm
        height={200}
        show={popupCancel}
        title={popupTitle}
        message={popupMessage}
        textConfirm={popupTextConfirm}
        textCancel="Kembali"
        onConfirm={() => handleConfirm(popupTitle)}
        onCancel={() => setPopupCancel(false)}
      />
    </>
  );
};
