import { ComponentProps } from "@declarations/props";
import { linkPage } from "@utils/router";
import { FiFileText, FiHome, FiInbox, FiSettings, FiShoppingBag, FiShoppingCart, FiUser } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";

export const BottomNavigation: ComponentProps<{
  inboxCount: boolean;
  type: "home" | "marketplace";
}> = ({ inboxCount = 0, type = "home" }) => {
  const location = useLocation();

  switch (type) {
    case "home":
      return (
        <div id="footer-bar" className="footer-bar-5">
          <Link
            to={linkPage.HOME_DASHBOARD}
            replace={true}
            className={
              location.pathname === linkPage.HOME_DASHBOARD ? "active-nav" : ""
            }
          >
            <FiHome
              size={24}
              data-feather-color="blue-dark"
              data-feather-bg="blue-fade-light"
            />
            <span>Home</span>
            <strong></strong>
          </Link>
          <Link
            to={linkPage.HOME_INBOX}
            replace={true}
            className={
              location.pathname === linkPage.HOME_INBOX ||
              location.pathname === linkPage.HOME_INBOX_DETAIL
                ? "active-nav"
                : ""
            }
          >
            <i>
              <FiInbox
                size={24}
                data-feather-color="green-dark"
                data-feather-bg="green-fade-light"
              />
              {inboxCount && (
                <div className="tw-absolute tw-inline-flex tw-items-center tw-justify-center tw-w-2 tw-h-2 tw-text-xs tw-font-bold tw-text-white tw-bg-red-500 tw-border-2 tw-border-white tw-rounded-full tw--top-2 tw--right-2 dark:tw-border-gray-900" />
              )}
            </i>

            <span>Inbox</span>
            <strong></strong>
          </Link>
          <Link
            to={linkPage.HOME_HISTORY}
            replace={true}
            className={
              location.pathname === linkPage.HOME_HISTORY ? "active-nav" : ""
            }
          >
            <FiFileText
              size={24}
              data-feather-color="brown-dark"
              data-feather-bg="brown-fade-light"
            />
            <span>History</span>
            <strong></strong>
          </Link>
          <Link
            to={linkPage.HOME_PROFILE}
            replace={true}
            className={
              location.pathname === linkPage.HOME_PROFILE ? "active-nav" : ""
            }
          >
            <FiUser
              size={24}
              data-feather-color="dark-dark"
              data-feather-bg="gray-fade-light"
            />
            <span>Profile</span>
            <strong></strong>
          </Link>
        </div>
      );
    case "marketplace":
      return (
        <div id="footer-bar" className="footer-bar-5">
          <Link
            to={linkPage.MARKETPLACE_PRODUCT}
            replace={true}
            className={
              location.pathname === linkPage.MARKETPLACE_PRODUCT ? "active-nav" : ""
            }
          >
            <FiShoppingBag
              size={24}
              data-feather-color="brown-dark"
              data-feather-bg="brown-fade-light"
            />
            <span>Produk</span>
            <strong></strong>
          </Link>
          <Link
            to={linkPage.MARKETPLACE_CHECKOUT}
            replace={true}
            className={
              location.pathname === linkPage.MARKETPLACE_CHECKOUT ? "active-nav" : ""
            }
          >
            <FiShoppingCart
              size={24}
              data-feather-color="green-dark"
              data-feather-bg="green-fade-light"
            />
            <span>Keranjang</span>
            <strong></strong>
          </Link>
          <Link
            to={linkPage.MARKETPLACE_HISTORY}
            replace={true}
            className={
              location.pathname === linkPage.MARKETPLACE_HISTORY ? "active-nav" : ""
            }
          >
            <FiFileText
              size={24}
              data-feather-color="blue-dark"
              data-feather-bg="blue-fade-light"
            />
            <span>Pesanan</span>
            <strong></strong>
          </Link>
          <Link
            to={linkPage.MARKETPLACE_SETTING}
            replace={true}
            className={
              location.pathname === linkPage.MARKETPLACE_SETTING ? "active-nav" : ""
            }
          >
            <FiSettings
              size={24}
              data-feather-color="dark-dark"
              data-feather-bg="gray-fade-light"
            />
            <span>Setting</span>
            <strong></strong>
          </Link>
        </div>
      );
  }
};
