import { ObjectSchema, object, string, ref } from "yup";

// ------------- LOGIN -------------
export type AuthLogin = {
  username: string;
  password: string;
};

export const authLoginSchema: ObjectSchema<AuthLogin> = object({
  username: string().required(),
  password: string().required(),
});
// ------------- LOGIN -------------

// -------- FORGOT PASSWORD --------
export type AuthForgotPassword = {
  email: string;
};

export const authForgotPasswordSchema: ObjectSchema<AuthForgotPassword> =
  object({
    email: string().email().required(),
  });
// -------- FORGOT PASSWORD --------

// -------- RESET PASSWORD --------
export type AuthResetPassword = {
  code: string;
  password: string;
  confirmPassword: string;
};

export const authResetPasswordSchema: ObjectSchema<AuthResetPassword> = object({
  code: string().required(),
  password: string()
    .required("Password harus diisi")
    .min(4, "Password min. 6 karakter"),
  confirmPassword: string()
    .required()
    .oneOf([ref("password")], "Password harus sama"),
});
// -------- RESET PASSWORD --------

// --------- UPDATE ACCOUNT --------
export type AuthUpdateAccount = {
  email: string;
  nohp: string;
  password: string;
};

export const authUpdateAccountSchema: ObjectSchema<AuthUpdateAccount> = object({
  email: string().email().required(),
  nohp: string().required(),
  password: string().required(),
});
// --------- UPDATE ACCOUNT --------
